import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  connect() {
    const defaultConfig = {
      searchField: [],
      controlInput: null,
      openOnFocus: false,
      sortField: {
        field: "text",
        direction: "asc"
      }
    };

    if (this.element.id === 'cta-icon-select') {
      defaultConfig.render = {
        option: function (data, escape) {
          return `<div>
                    <i class="fa fa-duotone fa-${escape(data.value)} mr-2"></i>
                    <span>${escape(data.text)}</span>
                  </div>`;
        },
        item: function (data, escape) {
          return `<div>
                    <i class="fa fa-duotone fa-${escape(data.value)} mr-2"></i>
                    <span>${escape(data.text)}</span>
                  </div>`;
        }
      };
    }

    const tomSelectInstance = new TomSelect(this.element, defaultConfig);

    document.getElementById(tomSelectInstance.control.id).addEventListener('click', function() {
      tomSelectInstance.open();
    });

    document.getElementById(tomSelectInstance.control.id).addEventListener('keydown', function(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        tomSelectInstance.open();
      }
    });
  }
}
