import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  connect() {
    const defaultConfig = {
      searchField: [],
      controlInput: null,
      openOnFocus: false,

      onInitialize:function(){
        const ariaLabelMap = {
          "bill-detail-bill-activity-dropdown-ts-control": "Select Bill Activity",
          "leg-dropdown-ts-control": "Select an Option",
          "longbill-dropdown-ts-control": "Select a Long Bill Version",
        };

        Object.keys(ariaLabelMap).forEach(id => {
          let element = document.getElementById(id);
          if (element) {
            element.setAttribute("aria-label", ariaLabelMap[id]);
          }
        });
      }
    };

    if (this.element.id === 'committee-pick-date') {
      defaultConfig.render = {
        option: function (data, escape) {
          return `<div>
                    <span>${escape(data.text)}</span>
                    <span class="cmhi-items-count ml-2">${escape(data.count)}</span>
                  </div>`;
        },
        item: function (data, escape) {
          return `<div>
                    <span>${escape(data.text)}</span>
                    <span class="cmhi-items-count ml-2">${escape(data.count)}</span>
                  </div>`;
        }
      };
    } else if(this.element.id === 'bill-detail-bill-activity-dropdown') {
      defaultConfig.render = {
        option: function (data, escape) {
          return `<h3 class='mb-0 font-size-16 font-400'>
                    ${escape(data.text)}
                  </h3>`;
        },
        item: function (data, escape) {
          return `<h3 class='mb-0 font-size-16 font-400'>
                    ${escape(data.text)}
                  </h3>`;
        }
      };
    }

    // Initialize Tom Select when the controller is connected
    const tomSelectInstance = new TomSelect(this.element, defaultConfig);

    document.getElementById(tomSelectInstance.control.id).addEventListener('mouseup', function() {
      if (!tomSelectInstance.isOpen) {
        setTimeout(() => {
          tomSelectInstance.open();
        }, 0);
      }
    });

    document.getElementById(tomSelectInstance.control.id).addEventListener('keydown', function(event) {
      if (event.key === 'Enter' && !tomSelectInstance.isOpen) {
        event.preventDefault();
        tomSelectInstance.open();
      }
    });
  }
}
